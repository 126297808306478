<template>
  <div class="error-msg" v-html="errorMessage">

  </div>
</template>

<script>
export default {
  name: 'ErrorMessage',
  props: {
    errorMessage: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.error-msg {
  color: rgba(255, 0, 0, 0.5);
  padding-top: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
</style>
